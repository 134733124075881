
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Raccolgo qui alcuni degli strumenti che scrivo per semplificare il mio lavoro quotidiano.`}</p>
    <p>{`Usali liberamente! Spero che ti possano tornare utili.`}</p>
    <p><a parentName="p" {...{
        "href": "escape-shell-strings-tool"
      }}>{`Escape di stringhe shell (SH, BASH, ZSH)`}</a><br parentName="p"></br>{`
`}{`Uso questo strumento quando devo fare l’`}<em parentName="p">{`escape`}</em>{` di stringhe per comandi shell e non riesco a contare tutti i `}<em parentName="p">{`single quote`}</em>{`, i `}<em parentName="p">{`double quote`}</em>{` e i `}<em parentName="p">{`backslash`}</em>{` che digito.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    